import React from "react";

import Layout from "components/layout";
import Seo from "components/seo";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>Pas trouvé</h1>
    <p>Réessayer</p>
  </Layout>
);

export default NotFoundPage;
